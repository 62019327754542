@tailwind base;
@tailwind components;
@tailwind utilities;

/* PrimeReact and other global styles */
@import 'primereact/resources/themes/saga-blue/theme.css'; 
@import 'primereact/resources/primereact.min.css';
@import 'primeicons/primeicons.css';

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

body {
  font-family: 'Graphik Trial';
  background-color: #E2E2E2;
  overflow-x: hidden;
}

.bg-gradient-custom{
     background: linear-gradient(45deg, #000, #d50036); 
}
.bg-gradient-custom-2{
  background: linear-gradient(45deg, #6c6c6c, #d50036); 
}
.mega-menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

swiper {
  width: 100%;
  height: 100%;
}
.border-none{
  border-radius: 0;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background:transparent;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {

 
}

.swiper-slide {
  width: 100%!important;
  height: 400px;
}

.swiper-slide:nth-child(2n) {
  width: 95%!important;
  height: 400px;
}

.swiper-slide:nth-child(3n) {
  width: 95%!important;
  height: 400px;
}
.surface-section{
  background: #E2E2E2!important;
}
.border-bottom-custom{
  border-bottom: 1px solid #d30056;
  margin-left: 0.5px;
}
.border-custom{
  border: 1px solid #E2E2E2;
  border-radius: 2px;
}
.border-custom-2{
  border: 0.2px solid #d30056;
  border-radius: 10px;
}
.border-bottom-custom-2{
  border-bottom: 1px solid #ffffff;
  margin-left: 0.5px;
}
.border-bottom-custom-3{
  border-bottom: 1px solid #E2E2E2;
  margin-left: 0.5px;
}
.border-button{
  border: 2px solid #d30056;
  border-radius: 0.2rem;
}
.swiper-pagination-bullet-active {
  background: #d50036!important;
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: var(--swiper-pagination-bottom, 30px)!important;
}
.alternate-pricing .border-custom-2 {
  border: 0.2px solid #fff;
  border-radius: 10px;
}
.alternate-pricing h2, .alternate-pricing span {
color: #fff;
}


.p-breadcrumb {
  background-color: #000;
  border-radius: 0;
}

.p-breadcrumb-list{
margin-left: 1.6rem;
}

.w-unset{
  width: unset!important;
}
.p-breadcrumb .p-breadcrumb-list li .p-menuitem-link .p-menuitem-text {
 
  font-size: 0.8rem;
}

.font-300{
  font-weight: 300!important;
}

.bg-gradient-custom-3{
  background: rgb(213,0,54);
background: linear-gradient(126deg, rgba(213,0,54,1) 60%, #B8C7E0 100%);
}

.font-thin{
  font-weight: 300;
}

.bg-gradient-yellow{
  background: rgb(253,243,136);
background: linear-gradient(180deg, rgba(253,243,136,1) 62%, rgba(255,255,255,1) 100%);
}

.p-breadcrumb .p-breadcrumb-list li .p-menuitem-link .p-menuitem-text {
  color:#fff;
}

.faint-red{
  background: rgb(255,250,255);
background: linear-gradient(120deg, rgba(255,250,255,1) 24%, rgba(255,196,193,1) 100%);
}

.faint-red-dark{
  background: rgb(255,0,33);
background: linear-gradient(63deg, rgba(255,0,33,1) 24%, rgba(255,196,193,1) 100%);
  
}

.faint-sky-blue{
  background: rgb(255,255,255);
  background: linear-gradient(120deg, rgba(255,255,255,1) 24%, rgba(149,239,253,1) 100%);
}

.faint-sky-blue-dark{
  background: rgb(99,185,255);
  background: linear-gradient(120deg, rgba(99,185,255,1) 24%, rgba(149,239,253,1) 100%);
}

.faint-purple{
  background: rgb(255,255,255);
  background: linear-gradient(120deg, rgba(255,255,255,1) 24%, rgba(157,184,252,1) 100%);
}

.faint-purple-dark{
  background: rgb(152,50,241);
  background: linear-gradient(120deg, rgba(152,50,241,1) 24%, rgba(157,184,252,1) 100%);
}

.faint-yellow{
  background: rgb(255,253,152);
background: linear-gradient(330deg, rgba(255,253,152,1) 24%, rgba(255,255,255,1) 100%);
}

.faint-yellow-dark{
  background: rgb(255,236,79);
  background: linear-gradient(120deg, rgba(255,236,79,1) 24%, rgba(255,253,148,1) 100%);
}

.faint-light-grey{
  background: rgb(255,255,255);
background: linear-gradient(120deg, rgba(255,255,255,1) 24%, rgba(186,213,238,1) 100%);
}

.brand-red-text{
  color: #ff0027;
}
.brand-white-text{
  color: #B8C7E0;
}
.brand-sky-text{
  color: #63b9ff;
}
.brand-purple-text{
  color: #9934f1;
}

.brand-grey-text{
color: #747a84;
}