.grid-container {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  
  /* Media Query outside the .grid-container */
  @media (max-width: 640px) {
    .grid-container {
      grid-template-columns: repeat(2, minmax(0, 1fr)); /* 2 columns on mobile */
    }
  }
  